import { FC } from 'react';

export const SeverityLevelsExplained: FC = () => {
  return (
    <div className="row px-2 fw-bold">
      <div className="col-3 p-0">Level</div>
      <div className="col-9 p-0">Description and Examples</div>
      <div className="separator border-gray-200 my-2" />
      <div className="col-3 p-0">Level 1-Critical</div>
      <div className="col-9 p-0">
        Critical production issue affecting all users, including system unavailability and data
        integrity issues with no workaround available.
      </div>
      <div className="separator border-gray-200 my-2" />
      <div className="col-3 p-0">Level 2-High</div>
      <div className="col-9 p-0">
        System performance issue or bug affecting some but not all users. Short-term workaround is
        available, but not scalable.
      </div>
      <div className="separator border-gray-200 my-2" />
      <div className="col-3 p-0">Level 3-Medium</div>
      <div className="col-9 p-0">
        Inquiry regarding a routine technical issue; information requested on application
        capabilities, navigation, installation or configuration; bug affecting a small number of
        users. Reasonable workaround available. Resolution required as soon as reasonably
        practicable.
      </div>
      <div className="separator border-gray-200 my-2" />
      <div className="col-3 p-0">Level 4-Low</div>
      <div className="col-9 p-0">
        Nice to have, can be a UI improvement. Does not affect the operation or has a very easy work
        around.
      </div>
    </div>
  );
};
