import { PresentationModal } from '@module/auth/components/PresentationModal';
import { Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { config } from '../../../../app-config';
import { OngageLogo } from '../../../ui/ongage-logo';

const AuthLayout = () => {
  return (
    <div className="min-w-325px d-flex flex-column flex-lg-row h-100">
      <PresentationModal />
      <ToastContainer
        transition={Slide}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        position="top-right"
        containerId="top"
        closeOnClick
        hideProgressBar
      />
      {/* begin::Body */}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 h-100">
        {/* begin::Logo */}
        <div>
          <OngageLogo height={32} linkTo={config.publicUrl} />
        </div>
        {/* end::Logo */}

        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-grow-1">
          {/* begin::Wrapper */}
          <div className="w-275px w-sm-375px">
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className="d-flex flex-center flex-wrap px-5">
          {/* begin::Links */}
          <div className="d-flex fw-semibold text-gray-600 fs-base">
            <Row className="text-center">
              <span>
                Your data will be handled in accordance with our
                <a
                  href={config.privacyPolicyUrl}
                  className="px-1 link-primary"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy.
                </a>
              </span>
              <span>
                By clicking on "Sign in" you agree to our
                <a
                  href={config.termsOfServiceUrl}
                  className="px-1 link-primary"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Service.
                </a>
              </span>
            </Row>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 position-relative"
        style={{ background: '#FCFCFD' }}
      >
        <div
          style={{
            padding: '0 0 80px 80px',
            position: 'absolute',
            bottom: '55%',
            left: '35%',
            height: '100%',
            width: '100%',
            borderRadius: '50%',
            background:
              'radial-gradient(62.2% 62.2% at 50% 50%, rgba(236, 72, 153, 0.20) 0%, rgba(237, 192, 34, 0.00) 77.6%, rgba(236, 72, 153, 0.00) 100%)',
            filter: 'blur(16px)',
          }}
        />
        <div
          style={{
            background: `url(${toAbsoluteUrl('/media/illustrations/astro-main.png')}) no-repeat`,
            width: '200px',
            position: 'absolute',
            height: '230px',
            top: '50%',
            left: '20%',
            zIndex: 1,
            transform: 'rotate(-8deg)',
          }}
        />

        <div
          style={{
            background: `url(${toAbsoluteUrl('/media/illustrations/cosmo.png')}) no-repeat`,
            position: 'absolute',
            bottom: '0',
            right: '0%',
            width: '90%',
            height: '24%',
            zIndex: 1,
          }}
        />
      </div>
      {/* end::Aside */}
    </div>
  );
};

export { AuthLayout };
