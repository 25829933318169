import { createContext, PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { TableColumn } from '@ui/table';
import { createStore, StoreApi, useStore } from 'zustand';
import { persist } from 'zustand/middleware';

interface StoredDataProps<T, K> {
  columns: TableColumn<T>[];
  tableKey: string;
  query: UseQueryResult<K, unknown>;
  columnsToOmit?: TableColumn<T>['accessorField'][];
}

export interface TableDataState<T, K> extends StoredDataProps<T, K> {
  actions: {
    setData: (state: StoredDataProps<T, K>) => void;
    hideColumn: (column: TableColumn<T>['accessorField']) => void;
    showColumn: (column: TableColumn<T>['accessorField']) => void;
    resetColumns: () => void;
  };
}

export type TableDataStore<T, K> = StoreApi<TableDataState<T, K>>;
const createTableColumnStore = <T, K>(initialProps: StoredDataProps<T, K>) => {
  return createStore<TableDataState<T, K>>()(
    persist(
      (set, get) => ({
        ...initialProps,
        actions: {
          setData: (state) => {
            set({ ...state });
          },
          hideColumn: (columnName) => {
            const columnsToOmit = get().columnsToOmit;
            set({
              columnsToOmit: columnsToOmit ? columnsToOmit.concat(columnName) : [columnName],
            });
          },
          showColumn: (columnName) => {
            const columnsToOmit = get().columnsToOmit;
            set({
              columnsToOmit: columnsToOmit ? columnsToOmit.filter((c) => c !== columnName) : [],
            });
          },
          resetColumns: () => {
            set({ columnsToOmit: [] });
          },
        },
      }),
      {
        name: initialProps?.tableKey || 'index',
        partialize: (state) => ({
          columnsToOmit: state.columnsToOmit,
          tableKey: state.tableKey,
        }),
      },
    ),
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableDataContext = createContext<TableDataStore<any, any> | null>(null);

export const TableDataProvider = <T, K>({
  children,
  ...props
}: PropsWithChildren<StoredDataProps<T, K>>) => {
  const storeRef = useRef<ReturnType<typeof createTableColumnStore<T, K>>>();
  if (!storeRef.current) {
    storeRef.current = createTableColumnStore<T, K>(props);
  }

  // I want to use source data as mutable state in the store
  // If columns are changed (f.e. something loading/counting), I want to update the store
  useEffect(() => {
    storeRef.current?.getState().actions.setData(props);
  }, [props]);

  return <TableDataContext.Provider value={storeRef.current}>{children}</TableDataContext.Provider>;
};

export const useTableDataContext = <T, U, K>(selector: (state: TableDataState<T, K>) => U) => {
  const store = useContext(TableDataContext) as TableDataStore<T, K>;
  if (!store) {
    throw new Error('useTableColumnContext must be used within TableColumnProvider');
  }
  return useStore(store, selector);
};
