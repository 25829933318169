import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface PaginationFilters {
  limit: number;
  page: number;
  offset: number | undefined;
  order: 'asc' | 'desc';
  sort: string | undefined;
  search: string | undefined;
}

const getFiltersFromSearchParams = <T extends PaginationFilters>(
  searchParams: URLSearchParams,
): T => {
  const filters = {
    limit: parseInt(searchParams.get('limit') || '10'),
    page: parseInt(searchParams.get('page') || '0'),
    offset: searchParams.get('offset') ? parseInt(searchParams.get('offset') || '0') : undefined,
    order: searchParams.get('order') === 'asc' ? 'asc' : 'desc',
    sort: searchParams.get('sort') || undefined,
    search: searchParams.get('search') || undefined,
  };
  searchParams.forEach((value, key) => {
    if (!(key in filters)) {
      filters[key] = value;
    }
    filters[key] = JSON.parse(value);
  });
  return filters as T;
};

export const useTableFilters = <T>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(
    () => getFiltersFromSearchParams<T & PaginationFilters>(searchParams),
    [searchParams],
  );

  const updateFilter = useMemo(
    () =>
      <K extends keyof typeof filters>(
        params: Array<{ key: K; value: (typeof filters)[K] }>,
      ): void => {
        setSearchParams((prev) => {
          const prevSearchParams = new URLSearchParams(prev);
          params.forEach(({ key, value }) => {
            const typedKey = key as string;
            if (!value) {
              prevSearchParams.has(typedKey) && prevSearchParams.delete(typedKey);
            } else {
              prevSearchParams.set(typedKey, JSON.stringify(value));
            }
          });
          return prevSearchParams;
        });
      },
    [setSearchParams],
  );

  const clearFilters = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      Object.keys(filters).forEach((key) => newParams.has(key) && newParams.delete(key));
      return newParams;
    });
  };

  return {
    filters,
    updateFilter,
    clearFilters,
  };
};
