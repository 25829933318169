import { FC, useEffect } from 'react';
import { IframeMessageProvider } from '@providers/IframeMessageProvider';
import posthog from 'posthog-js';
import { Outlet, useLocation } from 'react-router-dom';

import { useSetupAxios } from '../modules/auth';

const Page: FC = () => {
  useSetupAxios();

  const location = useLocation();
  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  return <Outlet />;
};

const RootPage = () => {
  return (
    <IframeMessageProvider>
      <Page />;
    </IframeMessageProvider>
  );
};

export default RootPage;
