import { FC, forwardRef, PropsWithChildren, ReactNode } from 'react';
import { useTimezones } from '@module/list/hooks/timezone-queries';
import { useUserInfo } from '@module/list/hooks/user-queries';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import { useIsActionAllowed } from '@module/shared/hooks/useActionIsAllowed';
import { ListIdPath, useWrappedTo } from '@routing/to';
import { Icon } from '@ui/Icon/Icon';
import { Tooltip } from '@ui/Tooltip';
import { ConditionalWrapper } from '@utils/ConditionalWrapper';
import { DateUIFormats } from '@utils/DateFieldFormats';
import { AclPrivileges } from '@utils/permissionsCheck';
import { ROUTES } from '@utils/routes';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import styles from './CurrentUserMenu.module.scss';
import { ExternalLink } from './ExternalLink';
import { useLogout } from '../../auth';
import { ltrToUpperCase } from '../menu/utils';
import { useSidebarAnimating, useSidebarMinimized } from '../sidebar-store';

const useCurrentTimezone = () => {
  const { data: user } = useUserInfo();
  const { data: account } = useAccountInfo();
  const { data: timezones } = useTimezones();

  const userTimezone = user?.payload.timezone || account?.timezone;
  return timezones?.payload.find((t) => t.name === userTimezone);
};

export const CurrentUserMenu: FC = () => {
  const timezone = useCurrentTimezone();
  const logout = useLogout();
  const hasAccountSettingsPermissions = useIsActionAllowed(
    AclPrivileges.ACCOUNT_SETTINGS,
    AclPrivileges.ACCOUNT_SAVE,
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={Toggle} />
      <Dropdown.Menu className={clsx(styles.menu, 'py-2')}>
        {hasAccountSettingsPermissions && (
          <>
            <div className="px-2">
              <MenuItem href={ROUTES.SETTINGS.PROFILE} icon={<Icon name="user" />}>
                View profile
              </MenuItem>
            </div>
            <Dropdown.Divider />
          </>
        )}

        <div className="px-2">
          <ExternalLink href="https://ongage.atlassian.net/wiki/spaces/HELP/overview">
            <Icon name="helpCircle" className="me-3" />
            Ongage Help
          </ExternalLink>
          <ExternalLink href="https://ongage.atlassian.net/wiki/x/FoAyB">
            <Icon name="container" className="me-3" />
            API FAQ
          </ExternalLink>
        </div>

        <Dropdown.Divider />

        <div className="px-2">
          <ExternalLink href="https://www.ongage.com/wp-content/terms-of-services.pdf?_gl=1*bkqys8*_ga*NjM3ODU2OTg0LjE3MjExMjU3NDE.*_ga_1W8327Z15G*MTcyMzU1ODI3My41OS4xLjE3MjM1NTgzMDAuMC4wLjA">
            <Icon name="fileSearch" className="me-3" />
            Terms of Service
          </ExternalLink>
          <ExternalLink href="https://www.ongage.com/wp-content/data-privacy.pdf?_gl=1*j3rcqw*_ga*NjM3ODU2OTg0LjE3MjExMjU3NDE.*_ga_1W8327Z15G*MTcyMzU1ODI3My41OS4xLjE3MjM1NTg1MTMuMC4wLjA">
            <Icon name="lock" className="me-3" />
            Privacy Policy
          </ExternalLink>
          <MenuItem>
            <Icon name="globe" className="me-3" />
            <Tooltip
              title={format(new Date(), DateUIFormats.HH_MM_DD_MMMM_YYYY)}
              colorTheme="dark"
              placement="right"
            >
              <span>User Timezone: {timezone?.gmt_display}</span>
            </Tooltip>
          </MenuItem>
        </div>
        <Dropdown.Divider />
        <div className="px-2">
          <MenuItem icon={<Icon name="logout" />} onClick={logout}>
            Log out
          </MenuItem>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Toggle = forwardRef<
  HTMLElement,
  {
    onClick: () => void;
  }
>(({ onClick }, ref) => {
  const isMinimized = useSidebarMinimized();
  const isAnimating = useSidebarAnimating();
  const { data, isPending } = useUserInfo();
  const { data: accInfo, isPending: isPendingAccInfo } = useAccountInfo();

  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.wrapper,
        'w-100 d-flex bg-white align-items-center py-2 rounded-1 shadow-xs app-sidebar-menu',
        { 'justify-content-start': !isMinimized, 'justify-content-center': isMinimized },
      )}
    >
      <div className="d-flex align-items-center gap-3 w-100">
        <div
          className={clsx(
            styles.symbolCircle,
            'symbol symbol-30px symbol-circle flex-grow-0 flex-shrink-0',
          )}
        >
          <Tooltip
            placement="right"
            description={<span>{`${data?.payload.first_name} ${data?.payload.last_name}`}</span>}
            colorTheme="dark"
          >
            <div className="symbol-label fs-7 fw-bold lh-sm">
              {data && (
                <>
                  {ltrToUpperCase(data.payload.first_name)}
                  {ltrToUpperCase(data.payload.last_name)}
                </>
              )}
            </div>
          </Tooltip>
        </div>
        {!isMinimized && !isAnimating && isPending && <Skeleton containerClassName="w-100 h-100" />}
        {isMinimized || isAnimating
          ? null
          : !isPending &&
            !isPendingAccInfo && (
              <div className="d-flex flex-column flex-grow-1 text-start text-truncate">
                <span
                  className="menu-title fs-6 fw-bold lh-sm text-truncate"
                  title={data?.payload.username}
                >
                  {data?.payload.username}
                </span>
                <span className="fs-7 text-truncate">{accInfo?.name}</span>
              </div>
            )}
        <span ref={ref}>
          {isMinimized || isAnimating ? null : <Icon className="flex-grow-0" name="dotsVertical" />}
        </span>
      </div>
    </button>
  );
});

interface MenuItemProps {
  href?: string;
  icon?: ReactNode;
  onClick?: () => void;
}

const MenuItem = forwardRef<HTMLDivElement, PropsWithChildren<MenuItemProps>>(
  ({ href, icon, children, onClick }, ref) => {
    const to = useWrappedTo();
    return (
      <Dropdown.Item
        as={'div'}
        className={clsx('d-flex align-items-center p-0 rounded cursor-pointer', { 'p-3': !href })}
        onClick={onClick}
        ref={ref}
      >
        <ConditionalWrapper
          condition={Boolean(href)}
          wrapper={(children) => (
            <Link
              className="text-gray-700 d-flex flex-grow-1 align-items-center p-3"
              to={to(href! as ListIdPath)}
            >
              {children}
            </Link>
          )}
        >
          <>
            {icon ? <span className="me-3">{icon}</span> : null}
            <span className="flex-grow-1">{children}</span>
          </>
        </ConditionalWrapper>
      </Dropdown.Item>
    );
  },
);
