import React, { useState } from 'react';
import { CheckboxField } from '@ui/CheckboxField';
import { TextField } from '@ui/TextField';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { ConfirmMfaModal } from './ConfirmMfaModal';
import config from '../../../../app-config';
import { useLogin } from '../queries';

const loginSchema = Yup.object().shape({
  login: Yup.string().min(3, 'Minimum 3 symbols').required('Login is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  accountCode: Yup.string().optional(),
  remember: Yup.boolean(),
});

export const LoginPasswordForm: React.FC = () => {
  const [showAccountCodeField, setShowAccountCodeField] = useState(false);
  const { mutateAsync: login, isPending } = useLogin();

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
      accountCode: '',
      remember: false,
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        await login({
          login: values.login,
          password: values.password,
          account_code: values.accountCode !== '' ? values.accountCode : undefined,
          rememberMe: values.remember,
        });
      } catch (error) {
        console.error(error);
        if (!(error instanceof AxiosError)) {
          return;
        }

        if (
          error.response?.status === 400 &&
          error.response.data.payload.errors.some((m) => m.includes('account_code'))
        ) {
          if (!showAccountCodeField) {
            setShowAccountCodeField(true);
            formik.setFieldTouched('accountCode', false, false);
          } else {
            formik.setFieldError('accountCode', 'Invalid account code');
          }
        }

        if (error.response?.status === 401) {
          setStatus('The login details are incorrect');
          formik.setFieldTouched('password', false, false);
          formik.setFieldTouched('login', false, false);
          if (showAccountCodeField) {
            formik.setFieldTouched('accountCode', false, false);
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form className="form" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        {/* begin::Heading */}
        <div className="text-start mb-10">
          <h1 className="text-gray-900 fw-bold fs-1 mb-4">Sign in</h1>
          <div className="text-gray-600 fw-normal fs-4">
            Welcome back! Please enter your details
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className="mb-lg-10 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="d-flex flex-column gap-5">
          <TextField
            {...formik.getFieldProps('login')}
            {...formik.getFieldMeta('login')}
            placeholder="Enter your login"
            label="Login"
          />

          <TextField
            {...formik.getFieldProps('password')}
            {...formik.getFieldMeta('password')}
            label="Password"
            type="password"
          />

          {showAccountCodeField && (
            <TextField
              {...formik.getFieldProps('accountCode')}
              {...formik.getFieldMeta('accountCode')}
              label="Account Code"
            />
          )}

          <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
            <div>
              <CheckboxField
                label="Remember for 30 days"
                checked={formik.values.remember}
                {...formik?.getFieldMeta('remember')}
                {...formik?.getFieldProps('remember')}
              />
            </div>
            <Link to="/auth/forgot-password" className="link-primary">
              Forgot Password
            </Link>
          </div>
        </div>

        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-info fs-6 fw-bold mb-10 w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {isPending ? (
            <span className="indicator-progress d-block">
              Please wait
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <span className="indicator-label">Sign in</span>
          )}
        </button>

        <div className="text-gray-500 text-center fw-semibold fs-6">
          Dont`t have an account?
          <a
            href={config.registrationUrl}
            className="px-1 link-primary"
            rel="noreferrer"
            target="_blank"
          >
            Sign up
          </a>
        </div>
      </form>
      <ConfirmMfaModal rememberMe={formik.values.remember} />
    </>
  );
};
