import { AuthLayout } from '@module/auth/components/AuthLayout';
import { FirstTimeLogin } from '@module/auth/components/FirstTimeLogin';
import { ForgotPassword } from '@module/auth/components/ForgotPassword';
import { ForgotPasswordLayout } from '@module/auth/components/ForgotPasswordLayout';
import { LoginPasswordForm } from '@module/auth/components/LoginPasswordForm';
import { PasswordResetComplete } from '@module/auth/components/PasswordResetComplete';
import { SentLinkMessage } from '@module/auth/components/SentLinkMessage';
import { SetNewPassword } from '@module/auth/components/SetNewPassword';
import { ErrorsPage } from '@module/errors/ErrorsPage';
import { ROUTES } from '@utils/routes';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { PrivateRoutes } from './PrivateRoutes';
import { useWrappedTo } from './to';
import RootPage from '../pages/RootPage';

const DashboardNavigate = () => {
  const to = useWrappedTo();
  return <Navigate to={to(ROUTES.LIST.DASHBOARD)} />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootPage />}>
      <Route path="error/*" element={<ErrorsPage />} />
      <Route path={ROUTES.AUTH.FIRST_LOGIN} element={<FirstTimeLogin />} />
      <Route path="/*" element={<PrivateRoutes />} />
      <Route path={ROUTES.AUTH.LOGIN} element={<AuthLayout />}>
        <Route index element={<LoginPasswordForm />} />
      </Route>
      <Route path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPasswordLayout />}>
        <Route index element={<ForgotPassword />} />
        <Route path={ROUTES.AUTH.FORGOT_PASSWORD_SENT_LINK} element={<SentLinkMessage />} />
        <Route path={ROUTES.AUTH.FORGOT_PASSWORD_RESET} element={<SetNewPassword />} />
        <Route
          path={ROUTES.AUTH.FORGOT_PASSWORD_RESET_COMPLETE}
          element={<PasswordResetComplete />}
        />
      </Route>
      <Route index element={<DashboardNavigate />} />
    </Route>,
  ),
);
