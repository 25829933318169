import { useUserInfo } from '@module/list/hooks/user-queries';
import { Icon } from '@ui/Icon/Icon';
import { ROUTES } from '@utils/routes';

import { NavigationMenu } from './NavigationMenu';

export const FooterMenu = () => {
  const { data, isPending } = useUserInfo();
  return (
    <NavigationMenu
      items={[
        {
          title: 'Support',
          icon: <Icon name="customerSupport" />,
          to: '#support',
        },
        {
          title: 'Settings',
          icon: <Icon name="settingsGear" />,
          to: ROUTES.SETTINGS.PROFILE,
        },
        {
          title: 'NOP',
          icon: <Icon name="shield" />,
          to: ROUTES.NOP.INDEX,
          blocked: !isPending && data ? !data.payload.is_super_admin : true,
        },
      ]}
      isLoading={false}
    />
  );
};
