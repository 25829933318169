import { useCallback } from 'react';
import { useSelectedListId } from '@module/list/store/useListStore';
import { useLocation } from 'react-router-dom';

export const ROUTES = {
  LIST: {
    DASHBOARD: '/:listId?/list/dashboard',
    IMPORT: '/:listId?/list/import',
    EXPORT: '/:listId?/list/export',
    SEGMENTS: '/:listId?/list/segments',
    NEW_SEGMENTS: '/:listId?/list/segments/new',
    EDIT_SEGMENT: '/:listId?/list/segments/:id',
    CONTACTS_MANAGER: '/:listId?/list/contacts-manager',
    NEW_CONTACT_SEARCH: '/:listId?/list/contacts-manager/new',
    CONTACT_SEARCH_REPORT: '/:listId?/list/contacts-manager/report/:id',
    FORM_BUILDER: '/:listId?/list/form-builder',
    NEW_FORM_BUILDER: '/:listId?/list/form-builder/new',
    EDIT_FORM_BUILDER: '/:listId?/list/form-builder/:id',
    COPY_FORM_BUILDER: '/:listId?/list/form-builder/:id/copy',
    CONVERSION_POINTS: '/:listId?/list/conversion-points',
    EDIT_CONVERSION_POINT: '/:listId?/list/conversion-points/:id',
    NEW_CONVERSION_POINTS: '/:listId?/list/conversion-points/new',
    AUTOMATION_RULES: '/:listId?/list/automation-rules',
    SETTINGS: '/:listId?/list/settings',
    NEW_FIELD: '/:listId?/list/settings/new',
  },
  CAMPAIGNS: {
    OVERVIEW: '/:listId?/campaigns/overview',
    A_B: '/:listId?/campaigns/a-b',
    EVENTS_AND_TRIGGERS: '/:listId?/campaigns/events-and-triggers',
    TRANSACTIONAL: '/:listId?/campaigns/transactional',
    JOURNEYS: '/:listId?/campaigns/journeys',
    CALENDAR: '/:listId?/campaigns/calendar',
  },
  AUTOMATION: {
    LIST: '/:listId?/automations/list',
    ANALYTICS: '/:listId?/automations/analytics',
    ANALYTICS_BY_WORKFLOW: '/:listId?/automations/analytics/:workflowId',
  },
  CONTENT: {
    TEMPLATES: '/:listId?/content/templates',
    IMAGES: '/:listId?/content/images',
    EMAILS: '/:listId?/content/emails',
    EMAIL_FORM: '/:listId?/content/emails/:id',
    SMS: '/:listId?/content/sms',
  },
  ANALYTICS: {
    INSIGHTS: '/:listId?/analytics/insights',
    OVERVIEW: '/:listId?/analytics/overview',
    AGGREGATE: '/:listId?/analytics/aggregate',
    MATRIX: '/:listId?/analytics/matrix',
    SPLIT_CAMPAIGNS: '/:listId?/analytics/split-campaigns',
    EVENTS: '/:listId?/analytics/events',
    TRANSACTIONAL: '/:listId?/analytics/transactional',
    CONTACTS_ACTIVITY: '/:listId?/analytics/contacts-activity',
    AGGREGATE_REPORT: '/:listId?/analytics/ag-report',
  },
  VENDORS: {
    CONNECTIONS: '/:listId?/vendors/connections',
    ESP_AND_SMTP: '/:listId?/vendors/esp-and-smtp',
    SMS: '/:listId?/vendors/sms',
  },
  VALIDATION: '/:listId?/validation',
  AI: '/:listId?/ai',
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password',
    FORGOT_PASSWORD_SENT_LINK: '/auth/forgot-password/sent-link/:email',
    FORGOT_PASSWORD_RESET: '/auth/forgot-password/reset',
    FORGOT_PASSWORD_RESET_COMPLETE: '/auth/forgot-password/pwd-reset-complete',
    FIRST_LOGIN: '/auth/first-login',
  },
  SETTINGS: {
    PROFILE: '/:listId?/settings/profile',
    ACCOUNT_SPECS: '/:listId?/settings/account-specs',
    DOMAIN_ROUTING: '/:listId?/settings/domain-routing',
    ACCOUNT_SETTINGS: '/:listId?/settings/account-settings',
    TRACKING_IMAGE_DOMAINS: '/:listId?/settings/tracking-image-domains',
    USER_MANAGEMENT: '/:listId?/settings/user-management',
    LIST_MANAGEMENT: '/:listId?/settings/list-management',
  },
  NOP: {
    INDEX: '/:listId?/nop',
  },
  SETUP: {
    WELCOME: '/:listId?/setup/welcome',
  },
} as const;

export const useTransformCurrentPathUsingList = () => {
  const location = useLocation();
  const selectedListId = useSelectedListId();

  return useCallback(
    (listId: number | 'all') => {
      if (selectedListId && location.pathname.includes(selectedListId.toString())) {
        return `${location.pathname.replace(selectedListId.toString(), listId.toString())}${location.search}`;
      }

      return `/${listId.toString()}${location.pathname}${location.search}`;
    },
    [location, selectedListId],
  );
};
