import React, { FC, lazy, PropsWithChildren, Suspense, useEffect } from 'react';
import { useAccessToken } from '@module/auth/store/authTokensStore';
import { ROUTES } from '@utils/routes';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import { useWrappedTo } from './to';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';

// Pages
const DashboardPage = lazy(() => import('../pages/list/DashboardPage'));
const ImportPage = lazy(() => import('../pages/list/ImportPage'));
const ExportPage = lazy(() => import('../pages/list/ExportPage'));
const SegmentsPage = lazy(() => import('../pages/list/SegmentsPage'));
const NewSegmentPage = lazy(() => import('../pages/list/NewSegmentPage'));
const IframeEditSegmentPage = lazy(() => import('../pages/list/EditSegmentPage'));
const ContactsManagerPage = lazy(() => import('../pages/list/ContactsManagerPage'));
const NewContactsSearchPage = lazy(() => import('../pages/list/NewContactsSearchPage'));
const ContactSearchReportPage = lazy(() => import('../pages/list/ContactSearchReportPage'));
const FormBuilderPage = lazy(() => import('../pages/list/FormBuilderPage'));
const NewFormBuilderPage = lazy(() => import('../pages/list/NewFormBuilderPage'));
const ConversionPointsPage = lazy(() => import('../pages/list/ConversionPointsPage'));
const NewConversionPointPage = lazy(() => import('../pages/list/NewConversionPointPage'));
const EditConversionPointPage = lazy(() => import('../pages/list/EditConversionPointPage'));
const ListSettingsPage = lazy(() => import('../pages/list/settings/ListSettingsPage'));
const ProfileSettingsPage = lazy(() => import('../pages/settings/ProfileSettingsPage'));
const AccountSpecsPage = lazy(() => import('../pages/settings/AccountSpecsPage'));
const DomainRoutingPage = lazy(() => import('../pages/settings/DomainRoutingPage'));
const AccountSettingsPage = lazy(() => import('../pages/settings/AccountSettingsPage'));
const TrackingImageDomains = lazy(() => import('../pages/settings/TrackingImageDomains'));
const UserManagement = lazy(() => import('../pages/settings/UserManagement'));
const ListManagement = lazy(() => import('../pages/settings/ListManagement'));
const AutomationsAnalyticsPage = lazy(() => import('../pages/marketing-automation/AnalyticsPage'));
const WorkflowNodesAnalyticsPage = lazy(
  () => import('../pages/marketing-automation/WorkflowNodesAnalyticsPage'),
);

// IFrames
const MarketingAutomationPage = lazy(
  () => import('../pages/marketing-automation/MarketingAutomationPage'),
);

const AIToolPage = lazy(() => import('../pages/AI/AIToolPage'));
const AutomationRulesPage = lazy(() => import('../pages/list/AutomationRulesPage'));
const CampaignOverviewPage = lazy(() => import('../pages/campaign/OverviewPage'));
const ABPage = lazy(() => import('../pages/campaign/ABPage'));
const CalendarPage = lazy(() => import('../pages/campaign/CalendarPage'));
const JourneyPage = lazy(() => import('../pages/campaign/JourneyPage'));
const CampaignTransactionalPage = lazy(() => import('../pages/campaign/TransactionalPage'));
const EventsAndTriggersPage = lazy(() => import('../pages/campaign/EventsAndTriggersPage'));
const MyConnectionsPage = lazy(() => import('../pages/vendors/MyConnectionsPage'));
const SMSVendorsPage = lazy(() => import('../pages/vendors/SMSVendorsPage'));
const ESPAndSMTPVendorsPage = lazy(() => import('../pages/vendors/ESPAndSMTPVendorsPage'));
const EmailMessagesPage = lazy(() => import('../pages/content/EmailMessagesPage'));
const EmailMessagesFormPage = lazy(() => import('../pages/content/EmailMessagesFormPage'));
const SMSMessagesPage = lazy(() => import('../pages/content/SMSMessagesPage'));
const ImageLibraryPage = lazy(() => import('../pages/content/ImageLibraryPage'));
const TemplatesLibraryPage = lazy(() => import('../pages/content/TemplatesLibraryPage'));
const TransactionalPage = lazy(() => import('../pages/analytics/TransactionalPage'));
const OverviewPage = lazy(() => import('../pages/analytics/OverviewPage'));
const AggregatePage = lazy(() => import('../pages/analytics/AggregatePage'));
const EventsPage = lazy(() => import('../pages/analytics/EventsPage'));
const MatrixPage = lazy(() => import('../pages/analytics/MatrixPage'));
const ContactActivityPage = lazy(() => import('../pages/analytics/ContactActivityPage'));
const InsightsPage = lazy(() => import('../pages/analytics/InsightsPage'));
const SplitCampaignsPage = lazy(() => import('../pages/analytics/SplitCampaignsPage'));
const CustomAggregateReportPage = lazy(
  () => import('../pages/analytics/CustomAggregateReportPage'),
);
const EmailValidationPage = lazy(() => import('../pages/email-validation/EmailValidationPage'));
const IframeEditFormBuilderPage = lazy(() => import('../pages/list/EditFormBuilderPage'));
const IframeCopyFormBuilderPage = lazy(() => import('../pages/list/CopyFormBuilderPage'));
const NopIndexPage = lazy(() => import('../pages/nop/NopIndexPage'));
const SetupWelcome = lazy(() => import('../pages/setup/SetupWelcome'));

const routes: Array<{
  path: string;
  element: React.LazyExoticComponent<React.FC>;
  supportsAllList?: boolean;
}> = [
  {
    path: ROUTES.LIST.DASHBOARD,
    element: DashboardPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.LIST.IMPORT,
    element: ImportPage,
  },
  {
    path: ROUTES.LIST.EXPORT,
    element: ExportPage,
  },
  {
    path: ROUTES.LIST.SEGMENTS,
    element: SegmentsPage,
  },
  {
    path: ROUTES.LIST.NEW_SEGMENTS,
    element: NewSegmentPage,
  },
  {
    path: ROUTES.LIST.EDIT_SEGMENT,
    element: IframeEditSegmentPage,
  },
  {
    path: ROUTES.LIST.CONTACTS_MANAGER,
    element: ContactsManagerPage,
  },
  {
    path: ROUTES.LIST.NEW_CONTACT_SEARCH,
    element: NewContactsSearchPage,
  },
  {
    path: ROUTES.LIST.CONTACT_SEARCH_REPORT,
    element: ContactSearchReportPage,
  },
  {
    path: ROUTES.LIST.FORM_BUILDER,
    element: FormBuilderPage,
  },
  {
    path: ROUTES.LIST.NEW_FORM_BUILDER,
    element: NewFormBuilderPage,
  },
  {
    path: ROUTES.LIST.EDIT_FORM_BUILDER,
    element: IframeEditFormBuilderPage,
  },
  {
    path: ROUTES.LIST.COPY_FORM_BUILDER,
    element: IframeCopyFormBuilderPage,
  },
  {
    path: ROUTES.LIST.CONVERSION_POINTS,
    element: ConversionPointsPage,
  },
  {
    path: ROUTES.LIST.NEW_CONVERSION_POINTS,
    element: NewConversionPointPage,
  },
  {
    path: ROUTES.LIST.EDIT_CONVERSION_POINT,
    element: EditConversionPointPage,
  },
  {
    path: ROUTES.LIST.AUTOMATION_RULES,
    element: AutomationRulesPage,
  },
  {
    path: ROUTES.LIST.SETTINGS,
    element: ListSettingsPage,
  },
  {
    path: ROUTES.AUTOMATION.ANALYTICS,
    element: AutomationsAnalyticsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.AUTOMATION.ANALYTICS_BY_WORKFLOW,
    element: WorkflowNodesAnalyticsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.AUTOMATION.LIST,
    element: MarketingAutomationPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.AI,
    element: AIToolPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.CAMPAIGNS.OVERVIEW,
    element: CampaignOverviewPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.CAMPAIGNS.A_B,
    element: ABPage,
  },
  {
    path: ROUTES.CAMPAIGNS.CALENDAR,
    element: CalendarPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.CAMPAIGNS.JOURNEYS,
    element: JourneyPage,
  },
  {
    path: ROUTES.CAMPAIGNS.TRANSACTIONAL,
    element: CampaignTransactionalPage,
  },
  {
    path: ROUTES.CAMPAIGNS.EVENTS_AND_TRIGGERS,
    element: EventsAndTriggersPage,
  },
  {
    path: ROUTES.VENDORS.CONNECTIONS,
    element: MyConnectionsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.VENDORS.SMS,
    element: SMSVendorsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.VENDORS.ESP_AND_SMTP,
    element: ESPAndSMTPVendorsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.CONTENT.EMAILS,
    element: EmailMessagesPage,
  },
  {
    path: ROUTES.CONTENT.EMAIL_FORM,
    element: EmailMessagesFormPage,
  },
  {
    path: ROUTES.CONTENT.SMS,
    element: SMSMessagesPage,
  },
  {
    path: ROUTES.CONTENT.IMAGES,
    element: ImageLibraryPage,
  },
  {
    path: ROUTES.CONTENT.TEMPLATES,
    element: TemplatesLibraryPage,
  },
  {
    path: ROUTES.ANALYTICS.TRANSACTIONAL,
    element: TransactionalPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.ANALYTICS.OVERVIEW,
    element: OverviewPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.ANALYTICS.AGGREGATE,
    element: AggregatePage,
    supportsAllList: true,
  },
  {
    path: ROUTES.ANALYTICS.EVENTS,
    element: EventsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.ANALYTICS.MATRIX,
    element: MatrixPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.ANALYTICS.CONTACTS_ACTIVITY,
    element: ContactActivityPage,
  },
  {
    path: ROUTES.ANALYTICS.INSIGHTS,
    element: InsightsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.ANALYTICS.SPLIT_CAMPAIGNS,
    element: SplitCampaignsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.ANALYTICS.AGGREGATE_REPORT,
    element: CustomAggregateReportPage,
  },
  {
    path: ROUTES.VALIDATION,
    element: EmailValidationPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.PROFILE,
    element: ProfileSettingsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.ACCOUNT_SPECS,
    element: AccountSpecsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.DOMAIN_ROUTING,
    element: DomainRoutingPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.ACCOUNT_SETTINGS,
    element: AccountSettingsPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.TRACKING_IMAGE_DOMAINS,
    element: TrackingImageDomains,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.USER_MANAGEMENT,
    element: UserManagement,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.LIST_MANAGEMENT,
    element: ListManagement,
    supportsAllList: true,
  },
  {
    path: ROUTES.NOP.INDEX,
    element: NopIndexPage,
    supportsAllList: true,
  },
  {
    path: ROUTES.SETUP.WELCOME,
    element: SetupWelcome,
  },
];

export const PrivateRoutes = () => {
  const accessToken = useAccessToken();
  const to = useWrappedTo();
  if (!accessToken) {
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to={to(ROUTES.LIST.DASHBOARD)} />} />
        {routes.map(({ path, element: Page, supportsAllList }, idx) => (
          <Route
            key={`${path}=${idx}`}
            path={path}
            element={
              <AllListsSupportGuard supportsAllLists={Boolean(supportsAllList)}>
                <SuspensedView>
                  <Page />
                </SuspensedView>
              </AllListsSupportGuard>
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

const AllListsSupportGuard: FC<
  PropsWithChildren<{
    supportsAllLists: boolean;
  }>
> = ({ supportsAllLists, children }) => {
  const to = useWrappedTo();
  const navigate = useNavigate();
  const { listId } = useParams();

  useEffect(() => {
    if (listId === 'all' && !supportsAllLists) {
      navigate(to(ROUTES.LIST.DASHBOARD));
    }
  }, [supportsAllLists, navigate, to, listId]);

  return <>{children}</>;
};
