import { FC, forwardRef, useCallback, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Icon } from '@ui/Icon/Icon';
import { toastError } from '@ui/Toasts';
import { createCsvFileFromBlob } from '@utils/common';
import { csvFileColumns } from '@utils/csvDownload';
import { AxiosError } from 'axios';
import { Col, Dropdown, DropdownToggleProps } from 'react-bootstrap';

import styles from '../../../../ui/table/controls/ControlWrapper.module.scss';
import { useAccountInfo } from '../../hooks/account-queries';
import { useTableFilters } from '../../hooks/useTableFilters';
import { ExportToCSVParams } from '../../models/models';
import { useTableDataContext } from '../../store/useTableColumns';

type Props = {
  name: string;
  mutationQuery: ReturnType<
    typeof useMutation<
      Blob,
      Error,
      Pick<ExportToCSVParams, 'csv_title' | 'columns' | 'format' | 'time_zone'>
    >
  >;
  useItemsPerPage?: boolean;
  itemsPerPage?: number[];
};

const defaultItemsPerPage = [10, 25, 50, 100];

export const CommonTableActions: FC<Props> = ({
  name,
  mutationQuery,
  itemsPerPage,
  useItemsPerPage = true,
}) => {
  const memoItemsPerPage = useRef(itemsPerPage || defaultItemsPerPage);
  const { data: account } = useAccountInfo();

  const columns = useTableDataContext((state) => state.columns);
  const { mutate: exportToCsv } = mutationQuery;

  const handleCsvExport = useCallback(() => {
    exportToCsv(
      {
        format: 'csv',
        columns: csvFileColumns(columns),
        csv_title: name,
        time_zone: account?.timezone || 'Asia/Jerusalem',
      },
      {
        onSuccess: (resp) => {
          createCsvFileFromBlob(resp, name);
        },
        onError: (error) => {
          toastError({
            title: 'Export csv',
            text:
              error instanceof AxiosError
                ? error.response?.data?.payload?.message
                : 'Something went wrong. Please refresh the page and try again.',
          });
        },
      },
    );
  }, [account?.timezone, columns, exportToCsv, name]);

  const [showItemsPerPage, setShowItemsPerPage] = useState(false);
  return (
    <Dropdown drop="down" autoClose="outside">
      <Dropdown.Toggle as="div" className={styles.controlWrapper}>
        <Icon name="dotsVertical" width={22} height={22} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border w-275px mt-4">
        <Dropdown.Item onClick={handleCsvExport}>
          <Col className="d-flex flex-row align-items-center gap-2 px-2 py-1">
            <Icon name="downloadCloud" />
            <span className="text-gray-700 fs-6">CSV</span>
          </Col>
        </Dropdown.Item>

        {useItemsPerPage && (
          <Dropdown.Item
            className="d-flex flex-row align-items-center"
            as={'span'}
            onClick={() => setShowItemsPerPage((s) => !s)}
          >
            <Col className="d-flex flex-row gap-2 px-2 py-1">
              <Icon name="arrowDown" />
              <span className="text-gray-700 fs-6">Items per page</span>
            </Col>
            <Col className="d-flex flex-row gap-2 px-2 py-1 justify-content-end">
              <ItemsPerPageDropdown
                itemsPerPage={memoItemsPerPage.current}
                show={showItemsPerPage}
              />
            </Col>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ItemsPerPageDropdown: FC<{ itemsPerPage: number[]; show: boolean }> = ({
  itemsPerPage,
  show,
}) => {
  const { filters, updateFilter } = useTableFilters();

  const handleLimitChange = (limit: number) => {
    updateFilter([
      { key: 'page', value: 0 },
      { key: 'offset', value: 0 },
      { key: 'limit', value: limit },
    ]);
  };
  return (
    <Dropdown drop="down-centered" show={show}>
      <Dropdown.Toggle as={ItemsPerPageTitle} />
      <Dropdown.Menu className="border w-200px mt-4">
        {itemsPerPage.map((limit) => (
          <Dropdown.Item key={limit} onClick={() => handleLimitChange(limit)}>
            <Col className="d-flex flex-row align-items-center gap-2 px-2 py-1 justify-content-between">
              <span className="text-gray-900 fw-semibold">{limit} items</span>
              {filters.limit === limit && <Icon name="check" stroke="primary" />}
            </Col>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ItemsPerPageTitle = forwardRef<HTMLSpanElement, DropdownToggleProps>(({ ...rest }, ref) => {
  const { filters } = useTableFilters();
  return (
    <span ref={ref} {...rest} className="text-gray-500 fs-6 justify-content-end">
      {filters.limit} items <Icon name="chevronDown" width={16} height={16} />
    </span>
  );
});
