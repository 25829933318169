import { User, UserWithAllowedListIds } from '@module/list/store/models';
import {
  SupportFormFields,
  SupportUploadFields,
} from '@module/settings/components/account-settings/components/SupportForm';
import { RoleType } from '@module/settings/components/tracking-image-domains/components/filters/UsersFilters';
import axios from 'axios';

import config from '../../../../app-config';
import {
  FapiResponse,
  OpenSupportTicketResponse,
  UploadSupportFileResponse,
  UserAclResponseType,
  UserErrorsResponse,
} from '../models/FapiResponse';
import { ExportToCSVParams, ListId, SearchParams } from '../models/models';

const API_URL = config.fapiUrl;

const GET_USERS_URL = `${API_URL}/api/user`;
const EDIT_USER_URL = (listId: number | 'all', userId?: number) =>
  `${API_URL}/${listId}/api/user/${userId}?check_users_limit_reached=1`;
const ADD_USER_URL = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/user?check_users_limit_reached=1`;
const GET_USER_INFO_URL = (id: number) => `${API_URL}/api/user/${id}`;
const EXPORT_CSV_URL = (listId: number | 'all') => `${API_URL}/${listId}/api/user`;
const GET_USER_ERRORS_URL = `${API_URL}/api/user/errors`;
const UPLOAD_SUPPORT_FILE = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/user/upload_support_file`;
const OPEN_SUPPORT_TICKET_URL = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/user/open_support`;
const GET_USER_ACL_PRIVILEGES = () => `${API_URL}/api/user/acl_privileges`;

export type UserFilters = {
  status?: string;
  role_id?: string;
  allowed_lists_ids?: string;
};

export const getUserAclPrivileges = async () =>
  axios.get<FapiResponse<UserAclResponseType>>(GET_USER_ACL_PRIVILEGES()).then(({ data }) => data);

export const getUsers = async (filters?: UserFilters & SearchParams) => {
  return await axios
    .get<FapiResponse<User[]>>(GET_USERS_URL, {
      params: {
        virtual_fields: ['role_title'],
        offset: filters?.offset,
        limit: filters?.limit,
        sort: filters?.sort,
        order: filters?.order,
        id: filters?.ids,
        power_search: filters?.search || undefined,
        deleted: filters?.status || '0',
        role_id: filters?.role_id === RoleType.ALL ? undefined : filters?.role_id,
        allowed_lists_ids:
          filters?.allowed_lists_ids === 'all' ? undefined : filters?.allowed_lists_ids,
      },
    })
    .then(({ data }) => data);
};

export const editUser = async ({ listId, ...user }: ListId & Partial<User>) => {
  return await axios
    .put<FapiResponse<User>>(EDIT_USER_URL(listId, user.id), { ...user })
    .then(({ data }) => data);
};

export const addUser = async ({ listId, ...user }: ListId & Partial<User>) => {
  return await axios
    .post<FapiResponse<User>>(ADD_USER_URL(listId), { ...user })
    .then(({ data }) => data);
};

export const removeUser = async ({ listId, userId }: ListId & { userId: number }) => {
  return await axios
    .delete<FapiResponse<User>>(EDIT_USER_URL(listId, userId))
    .then(({ data }) => data);
};

export const getUserInfo = async (id: number) => {
  return await axios.get<FapiResponse<User>>(GET_USER_INFO_URL(id)).then(({ data }) => data);
};

export const getUserAllowedLists = async (id: number) => {
  return await axios
    .get<FapiResponse<UserWithAllowedListIds>>(GET_USER_INFO_URL(id), {
      params: {
        get_allowed_list_ids: 1,
      },
    })
    .then(({ data }) => data.payload.allowed_list_ids);
};

export const exportUsersToCsv = async (
  listId: number | 'all',
  exportParams: Pick<ExportToCSVParams, 'csv_title' | 'columns' | 'format' | 'time_zone'>,
) => {
  return axios
    .get<Blob>(EXPORT_CSV_URL(listId), {
      params: {
        limit: 40_000,
        format: exportParams?.format,
        csv_title: exportParams?.csv_title,
        columns: exportParams?.columns ? JSON.stringify(exportParams?.columns) : undefined,
        list_id: listId,
        time_zone: exportParams?.time_zone,
      },
    })
    .then(({ data }) => data);
};

export const getUserErrors = async () => {
  return await axios
    .get<FapiResponse<UserErrorsResponse>>(GET_USER_ERRORS_URL)
    .then(({ data }) => data);
};

export const uploadSupportFile = async (listId: number | 'all', data: SupportUploadFields[]) => {
  const uploadPromises = data.map((fileData) => {
    const formData = new FormData();
    formData.append('action', fileData.action);
    formData.append('support_file', fileData.support_file);

    return axios
      .post<FapiResponse<UploadSupportFileResponse>>(UPLOAD_SUPPORT_FILE(listId), formData)
      .then(({ data }) => data.payload.s3_filename);
  });

  const filenames = await Promise.all(uploadPromises);
  return filenames;
};

export const openSupportTicket = async (listId: number | 'all', data: SupportFormFields) => {
  return await axios
    .post<FapiResponse<OpenSupportTicketResponse>>(OPEN_SUPPORT_TICKET_URL(listId), data)
    .then(({ data }) => data);
};
