import {
  STATUS_DESCRIPTIONS,
  StatusCode,
  STATUSES_WITH_PROGRESS,
  TimezoneOption,
} from '@module/list/store/models';
import { differenceInSeconds, fromUnixTime } from 'date-fns';

export const isTrue = (value: boolean | string | undefined) =>
  [0, '0', 'false', false, 'no', 'n', 'FALSE', 'NO', 'N', undefined].indexOf(value) === -1;

export const extractMonthAndYear = (dateString: string): { month: string; year: string } => {
  const regex = /^(\d{2})\/(\d{4})$/;
  const match = dateString.match(regex);

  if (!match) {
    throw new Error('Invalid date format. Expected format is MM/YYYY.');
  }

  const [, month, year] = match;

  return { month, year };
};

// Calculate time difference
export const processingTime = (start: string, end: string) => {
  // some aborted imports have no start and/or end time
  if (+start <= 0 || +end <= 0) {
    return '0s';
  }

  const startTime = fromUnixTime(+start); // Convert from seconds to milliseconds
  const endTime = fromUnixTime(+end);
  const diffInSeconds = differenceInSeconds(endTime, startTime);
  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;

  return `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`;
};

// Format chart axis ticks, add 'k' as in Legacy UI
export const axisTickFormatter = (value: number): string => {
  return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
};

// Sort timezone groups to match Legacy UI
export const sortTimezoneGroups = (timezones: TimezoneOption[]) => {
  const sorted = timezones.sort((a, b) => {
    const matchA = a.label.match(/GMT ([+-]\d+):/);
    const matchB = b.label.match(/GMT ([+-]\d+):/);
    const gmtA = matchA ? parseInt(matchA[1], 10) : 0;
    const gmtB = matchB ? parseInt(matchB[1], 10) : 0;

    return gmtA - gmtB;
  });

  return sorted;
};

// Format file sizes to human readable
export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 B';

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) {
    return (bytes / 1024).toFixed(2) + ' KB';
  }

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

// Check if the file is image
export const isImage = (file: File) => {
  return file && file.type.startsWith('image/');
};

// Support form upload files
export const maxFileSize = 5 * 1024 * 1024;
export const maxSupportFiles = 3;

// Contacts upload max file size
export const contactsMaxFileSize = 250 * 1024 * 1024;

// Check file size
export const fileSizeValidation = (files) => {
  const MAX_FILE_SIZE = maxFileSize; // 5 MB in bytes
  return files.every((file) => file.size <= MAX_FILE_SIZE);
};

export const createCsvFileFromBlob = (data: Blob, filename: string) => {
  const blob = new Blob([data || ''], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

// Function to get the status description by status code
export function getStatusDescription(statusCode: StatusCode, progress?: number): string {
  let description = STATUS_DESCRIPTIONS[statusCode] || 'Unknown Status';

  // Append progress percentage if it's a status that supports progress and within valid range
  if (STATUSES_WITH_PROGRESS.has(statusCode) && progress !== undefined && progress <= 100) {
    description += ` (${progress}%)`;
  }

  return description;
}
