import { useUserACL } from '@module/list/hooks/user-queries';
import { AclPrivileges } from '@utils/permissionsCheck';

export const useIsActionAllowed = (...permissionKeys: AclPrivileges[]): boolean => {
  const { data } = useUserACL();

  if (!data) {
    return false;
  }

  if (!data.restricted_permission) {
    return true;
  } else {
    return permissionKeys.every((key) => !!data.privileges[key]);
  }
};
