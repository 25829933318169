import { FC, useMemo } from 'react';
import { useAllListsSelected } from '@module/list/store/useListStore';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import { useIsActionAllowed } from '@module/shared/hooks/useActionIsAllowed';
import { Badge } from '@ui/Badge';
import { Icon } from '@ui/Icon/Icon';
import { AclPrivileges } from '@utils/permissionsCheck';
import { ROUTES } from '@utils/routes';
import { useIntl } from 'react-intl';

import { NavigationMenu, NavItem } from './NavigationMenu';

export const MainNavigationMenu: FC = () => {
  const intl = useIntl();
  const allListsSelected = useAllListsSelected();

  const { data: accountSettings, isPending } = useAccountInfo();

  // Permissions to disable menu items for current user
  const hasDashboardPermissions = useIsActionAllowed(
    AclPrivileges.LIST_VIEW,
    AclPrivileges.ANALYTICS_VIEW,
  );
  const hasListImportPermissions = useIsActionAllowed(AclPrivileges.LIST_IMPORT);
  const hasListExportPermissions = useIsActionAllowed(
    AclPrivileges.LIST_EXPORT,
    AclPrivileges.LIST_FIELDS_VIEW,
  );
  const hasSegmentPermissions = useIsActionAllowed(AclPrivileges.SEGMENT_VIEW);
  const hasContactsPermissions = useIsActionAllowed(AclPrivileges.CONTACT_VIEW);
  const hasFormBuilderPermissions = useIsActionAllowed(AclPrivileges.LIST_FORM_BUILDER);
  const hasConversionPointPermissions = useIsActionAllowed(AclPrivileges.CONVERSION_POINT_VIEW);
  const hasAutomationRulesPermissions = useIsActionAllowed(AclPrivileges.AUTOMATION_RULE_VIEW);
  const hasListSettingsPermissions = useIsActionAllowed(
    AclPrivileges.LIST_FIELDS_VIEW,
    AclPrivileges.LIST_FIELDS_SAVE,
  );
  const hasCampaignPermissions = useIsActionAllowed(AclPrivileges.CAMPAIGN_VIEW);
  const hasCampaignsABPermissions = useIsActionAllowed(AclPrivileges.CAMPAIGN_TEST);
  const hasEventsPermissions = useIsActionAllowed(AclPrivileges.EVENT_VIEW);
  const hasCampaignsTransactionalPermissions = useIsActionAllowed(AclPrivileges.CAMPAIGN_VIEW);
  const hasJourneysPermissions = useIsActionAllowed(AclPrivileges.EVENT_VIEW);
  const hasCalendarPermissions = useIsActionAllowed(AclPrivileges.CAMPAIGN_CALENDAR);
  const hasContentPermissions = useIsActionAllowed(AclPrivileges.TEMPLATE_VIEW);
  const hasTemplatePermissions = useIsActionAllowed(AclPrivileges.TEMPLATE_VIEW);
  const hasImagesPermissions = useIsActionAllowed(AclPrivileges.IMAGE_VIEW);
  const hasEmailPermissions = useIsActionAllowed(AclPrivileges.EMAIL_VIEW);
  const hasSmsMessagesPermissions = useIsActionAllowed(AclPrivileges.SMS_VIEW);
  const hasAnalyticsOverviewPermissions = useIsActionAllowed(AclPrivileges.ANALYTICS_VIEW);
  const hasAggregatePermissions = useIsActionAllowed(AclPrivileges.ANALYTICS_AGGREGATE);
  const hasMatrixPermissions = useIsActionAllowed(AclPrivileges.ANALYTICS_MATRIX);
  const hasSplitCampaignsPermissions = useIsActionAllowed(AclPrivileges.ANALYTICS_SPLIT);
  const hasAnalyticsEventsPermissions = useIsActionAllowed(AclPrivileges.ANALYTICS_EVENT);
  const hasTransactionalPermissions = useIsActionAllowed(AclPrivileges.ANALYTICS_TRANSACTIONAL);
  const hasContactsActivityPermissions = useIsActionAllowed(
    AclPrivileges.CONTACT_VIEW,
    AclPrivileges.LIST_VIEW,
  );
  const hasAggregateReportPermissions = useIsActionAllowed(
    AclPrivileges.LIST_VIEW,
    AclPrivileges.CONTACT_VIEW,
  );
  const hasVendorConnectionsPermissions = useIsActionAllowed(
    AclPrivileges.ESP_VIEW,
    AclPrivileges.ESP_SAVE,
    AclPrivileges.ESP_MULTIPLE,
  );
  const hasEspSmtpPermissions = useIsActionAllowed(
    AclPrivileges.ESP_VIEW,
    AclPrivileges.ESP_SAVE,
    AclPrivileges.ESP_MULTIPLE,
  );
  const hasSmsVendorsPermissions = useIsActionAllowed(
    AclPrivileges.ESP_VIEW,
    AclPrivileges.ESP_SAVE,
    AclPrivileges.ESP_MULTIPLE,
  );
  const hasValidationPermissions = useIsActionAllowed(AclPrivileges.EMAIL_VALIDATION_VIEW);

  // TODO: Missing permissions
  const hasAiPermissions = useIsActionAllowed(AclPrivileges.AI);

  const menuItems = useMemo<NavItem[]>(
    () => [
      {
        title: 'List',
        icon: <Icon name="list" />,
        sub: [
          {
            title: intl.formatMessage({ id: 'MENU.LIST.DASHBOARD' }),
            to: ROUTES.LIST.DASHBOARD,
            disabled: !hasDashboardPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.IMPORT' }),
            to: ROUTES.LIST.IMPORT,
            disabled: allListsSelected || !hasListImportPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.EXPORT' }),
            to: ROUTES.LIST.EXPORT,
            disabled: allListsSelected || !hasListExportPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.SEGMENTS' }),
            to: ROUTES.LIST.SEGMENTS,
            disabled: allListsSelected || !hasSegmentPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.CONTACTS_MANAGER' }),
            to: ROUTES.LIST.CONTACTS_MANAGER,
            disabled: allListsSelected || !hasContactsPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.FORM_BUILDER' }),
            to: ROUTES.LIST.FORM_BUILDER,
            disabled: allListsSelected || !hasFormBuilderPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.CONVERSION_POINTS' }),
            to: ROUTES.LIST.CONVERSION_POINTS,
            disabled: allListsSelected || !hasConversionPointPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.AUTOMATION_RULES' }),
            to: ROUTES.LIST.AUTOMATION_RULES,
            disabled: allListsSelected || !hasAutomationRulesPermissions,
          },
          {
            title: intl.formatMessage({ id: 'MENU.LIST.SETTINGS' }),
            to: ROUTES.LIST.SETTINGS,
            disabled: allListsSelected || !hasListSettingsPermissions,
          },
        ],
      },
      {
        title: 'Campaigns',
        icon: <Icon name="send" />,
        sub: [
          {
            title: 'Overview',
            to: ROUTES.CAMPAIGNS.OVERVIEW,
            disabled: !hasCampaignPermissions,
          },
          {
            title: 'A/B',
            to: ROUTES.CAMPAIGNS.A_B,
            disabled: allListsSelected || !hasCampaignsABPermissions,
          },
          {
            title: 'Events, Triggers',
            to: ROUTES.CAMPAIGNS.EVENTS_AND_TRIGGERS,
            disabled: allListsSelected || !hasEventsPermissions,
          },
          {
            title: 'Transactional',
            to: ROUTES.CAMPAIGNS.TRANSACTIONAL,
            disabled: allListsSelected || !hasCampaignsTransactionalPermissions,
          },
          {
            title: 'Journeys',
            to: ROUTES.CAMPAIGNS.JOURNEYS,
            disabled: allListsSelected || !hasJourneysPermissions,
            badge: <NewBadge />,
          },
          {
            title: 'Calendar',
            to: ROUTES.CAMPAIGNS.CALENDAR,
            disabled: !hasCalendarPermissions,
          },
        ],
      },
      {
        title: 'Automations',
        icon: <Icon name="dataflow" />,
        sub: [
          {
            title: 'Workflows',
            to: ROUTES.AUTOMATION.LIST,
            badge: <BetaBadge />,
          },
          {
            title: 'Analytics',
            to: ROUTES.AUTOMATION.ANALYTICS,
            badge: <BetaBadge />,
          },
        ],
        blocked: !accountSettings?.settings.marketing_automation?.enable,
      },
      {
        title: 'Content',
        icon: <Icon name="mail" />,
        disabled: !hasContentPermissions,
        sub: [
          {
            title: 'Templates Library',
            to: ROUTES.CONTENT.TEMPLATES,
            disabled: allListsSelected || !hasTemplatePermissions,
          },
          {
            title: 'Image Library',
            to: ROUTES.CONTENT.IMAGES,
            disabled: allListsSelected || !hasImagesPermissions,
          },
          {
            title: 'Email Messages',
            to: ROUTES.CONTENT.EMAILS,
            disabled: allListsSelected || !hasEmailPermissions,
          },
          {
            title: 'SMS Messages',
            to: ROUTES.CONTENT.SMS,
            disabled: allListsSelected || !hasSmsMessagesPermissions,
          },
        ],
      },
      {
        title: 'Analytics',
        icon: <Icon name="pieChart" />,
        sub: [
          // TODO: exists in Legacy, gives 500 error, as soon as it resoved this one can be enabled
          /* {
            title: 'Insights',
            to: ROUTES.ANALYTICS.INSIGHTS,
            badge: <BetaBadge />,
            disabled: !hasAnalyticsInsightsPermissions,
          }, */
          {
            title: 'AI',
            to: ROUTES.AI,
            badge: <ExperimentalBadge />,
            blocked: !accountSettings?.settings.analytics_ai?.enable,
            disabled: !hasAiPermissions,
          },
          {
            title: 'Analytics Overview',
            to: ROUTES.ANALYTICS.OVERVIEW,
            disabled: !hasAnalyticsOverviewPermissions,
          },
          {
            title: 'Aggregate',
            to: ROUTES.ANALYTICS.AGGREGATE,
            disabled: !hasAggregatePermissions,
          },
          {
            title: 'Matrix',
            to: ROUTES.ANALYTICS.MATRIX,
            disabled: !hasMatrixPermissions,
          },
          {
            title: 'Split Campaigns (A/B)',
            to: ROUTES.ANALYTICS.SPLIT_CAMPAIGNS,
            disabled: !hasSplitCampaignsPermissions,
          },
          {
            title: 'Events',
            to: ROUTES.ANALYTICS.EVENTS,
            disabled: !hasAnalyticsEventsPermissions,
          },
          {
            title: 'Transactional',
            to: ROUTES.ANALYTICS.TRANSACTIONAL,
            disabled: !hasTransactionalPermissions,
          },
          {
            title: 'Contacts Activity',
            to: ROUTES.ANALYTICS.CONTACTS_ACTIVITY,
            disabled: allListsSelected || !hasContactsActivityPermissions,
          },
          {
            title: 'Custom Aggregate Report',
            to: ROUTES.ANALYTICS.AGGREGATE_REPORT,
            disabled: allListsSelected || !hasAggregateReportPermissions,
          },
        ],
      },
      {
        title: 'Vendors',
        icon: <Icon name="plug" />,
        sub: [
          {
            title: 'My Connections',
            to: ROUTES.VENDORS.CONNECTIONS,
            disabled: !hasVendorConnectionsPermissions,
          },
          {
            title: 'ESP/SMTP Vendors',
            to: ROUTES.VENDORS.ESP_AND_SMTP,
            disabled: !hasEspSmtpPermissions,
          },
          {
            title: 'SMS Vendors',
            to: ROUTES.VENDORS.SMS,
            disabled: !hasSmsVendorsPermissions,
          },
        ],
      },
      {
        title: 'Validation',
        icon: <Icon name="messageAccepted" />,
        to: ROUTES.VALIDATION,
        disabled: !hasValidationPermissions,
      },
    ],
    [
      intl,
      allListsSelected,
      hasDashboardPermissions,
      hasListImportPermissions,
      hasListExportPermissions,
      hasSegmentPermissions,
      hasContactsPermissions,
      hasFormBuilderPermissions,
      hasConversionPointPermissions,
      hasAutomationRulesPermissions,
      hasListSettingsPermissions,
      hasCampaignPermissions,
      hasCampaignsABPermissions,
      hasEventsPermissions,
      hasCampaignsTransactionalPermissions,
      hasJourneysPermissions,
      hasCalendarPermissions,
      accountSettings?.settings.marketing_automation?.enable,
      accountSettings?.settings.analytics_ai?.enable,
      hasContentPermissions,
      hasTemplatePermissions,
      hasImagesPermissions,
      hasEmailPermissions,
      hasSmsMessagesPermissions,
      hasAiPermissions,
      hasAnalyticsOverviewPermissions,
      hasAggregatePermissions,
      hasMatrixPermissions,
      hasSplitCampaignsPermissions,
      hasAnalyticsEventsPermissions,
      hasTransactionalPermissions,
      hasContactsActivityPermissions,
      hasAggregateReportPermissions,
      hasVendorConnectionsPermissions,
      hasEspSmtpPermissions,
      hasSmsVendorsPermissions,
      hasValidationPermissions,
    ],
  );

  return <NavigationMenu items={menuItems} isLoading={isPending} />;
};

// TODO: Think about passing color prop to badges
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BetaBadge = () => (
  <Badge
    variant="outline"
    style={{
      borderColor: 'rgba(21, 112, 239, 1)',
      color: 'rgba(23, 92, 211, 1)',
    }}
  >
    Beta
  </Badge>
);

const NewBadge = () => (
  <Badge
    style={{
      background: 'rgba(249, 245, 255, 1)',
      border: 'border: 1px solid',
      borderColor: 'rgba(233, 215, 254, 1)',
      color: 'rgba(105, 65, 198, 1)',
    }}
  >
    New
  </Badge>
);

const ExperimentalBadge = () => (
  <Badge
    style={{
      background: 'rgba(249, 245, 255, 1)',
      border: 'border: 1px solid',
      borderColor: 'rgba(233, 215, 254, 1)',
      color: 'rgba(105, 65, 198, 1)',
    }}
  >
    Experimental
  </Badge>
);
